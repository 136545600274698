import maplibregl from '../node_modules/maplibre-gl/dist/maplibre-gl.js';
import $ from '../node_modules/jquery/dist/jquery.js';

maplibregl.accessToken = 'pk.eyJ1Ijoic2FtZWphciIsImEiOiJlZDIxYzVhYzgzZDczNDVjMjA5MjExNGU4ZjZkNjU4NiJ9.w4GGNyn0vZZUxBTfKbWz2g'; // Add your mapbox API key here

var map = new maplibregl.Map({
    container: 'map',
    style: 'mapbox://styles/samejar/ck0rzka4y056d1cpd55pgz853',
    center: [-2.192714, 54.384409], // your starting co-ordinates
    zoom: 4.5,
    bearing: 0,
    // pitch: 40,
    duration: 8000
});

map.scrollZoom.disable();

var chapters = {

    // give each location the same name as its section id, and set its coordinates here
    'intro': {
        center: [-2.192714, 54.384409], // your starting co-ordinates
        zoom: 4.5,
        bearing: 0,
        // pitch: 40,
        duration: 3000
    },
    'day-zero': {
        center: [-2.192714, 54.384409], // your starting co-ordinates
        zoom: 4.5,
        bearing: 0,
        // pitch: 40,
        duration: 3000
    },
    'day-one': {
        center: [-4.82600, 50.368458],
        zoom: 7.5,
        bearing: window.matchMedia("(max-width: 767px)").matches ? 30 : 0,
        // pitch: 40,
        duration: 3000
    },
    'day-two': {
        center: [-3.258, 50.972],
        zoom: 7.5,
        bearing: window.matchMedia("(max-width: 767px)").matches ? 30 : 0,
        // pitch: 70
        duration: 3000
    },
    'day-three': {
        center: [-2.534, 51.877],
        zoom: 7.5,
        bearing: 0,
        // pitch: 70
        duration: 3000
    },
    'day-four': {
        center: window.matchMedia("(max-width: 767px)").matches ? [-2.57, 52.878] : [-2.634, 53.022],
        zoom: 7.5,
        bearing: 0,
        // pitch: 70
        duration: 3000
    },
    'day-five': {
        center: window.matchMedia("(max-width: 767px)").matches ? [-2.751, 54.026] : [-2.54, 54.217],
        zoom: window.matchMedia("(max-width: 767px)").matches ? 7.1 : 7.5,
        bearing: 0,
        // pitch: 70
        duration: 3000
    },
    'day-six': {
        center: window.matchMedia("(max-width: 767px)").matches ? [-3.106, 55.248] : [-3.382, 55.438],
        zoom: window.matchMedia("(max-width: 767px)").matches ? 7.2 : 7.5,
        bearing: 0,
        // pitch: 70
        duration: 3000
    },
    'day-seven': {
        center: window.matchMedia("(max-width: 767px)").matches ? [-3.429, 56.469] : [-3.13, 56.491],
        zoom: window.matchMedia("(max-width: 767px)").matches ? 7.3 : 7.6,
        bearing: 0,
        // pitch: 70
        duration: 3000
    },
    'day-eight': {
        center: window.matchMedia("(max-width: 767px)").matches ? [-3.731, 57.338] : [-3.851, 57.571],
        zoom: window.matchMedia("(max-width: 767px)").matches ? 7.3 : 7.5,
        bearing: window.matchMedia("(max-width: 767px)").matches ? -20 : 0,
        // bearing: -15,
        // pitch: 70
        duration: 2000
    },
    'day-nine': {
        center: window.matchMedia("(max-width: 767px)").matches ? [-3.971, 58.174] : [-3.875, 58.35],
        zoom: 7.4,
        bearing: window.matchMedia("(max-width: 767px)").matches ? 20 : 0,
        // pitch: 70
        duration: 2000
    },
    'day-over': {
        center: [-2.192714, 54.384409], // your starting co-ordinates
        zoom: 4.5,
        bearing: 0,
        // pitch: 40,
        duration: 3000
    },
};

// Every time the page scrolls, we'll check which section is on the screen
$('#writing').scroll(function () {
    var arrPlaces = $("section");

    arrPlaces.each(function () {
        var offset = $(this).offset();
        var bottom = offset.top + $(this).outerHeight();
        var windowHeight = $(window).height();
        var theId = $(this).attr('id');
        if ($(window).width() > 767) {
            console.log(theId);
            if (offset.top < 200 && bottom > 200) {
                setActiveChapter(theId);
            }
        } else if (offset.top < windowHeight) {
            // this is for mobile; we trigger the map move as soon as a section enters the screen
            setActiveChapter(theId);
        } else {
            $(this).removeClass('active');
        }
    });
});

var activeChapterName = 'intro';

function setActiveChapter(theId) {
    if (null === theId)
        return;
    $('#' + theId).addClass('active');
    if (theId === activeChapterName) return;
    console.log('ID: ' + theId);
    console.log(chapters[theId]);
    map.flyTo(chapters[theId]);
    activeChapterName = theId;
};
